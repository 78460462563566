<template>
    <div v-if="loading">Loading...</div>
    <video v-else ref="videoRef" @ended="handleVideoEnd" :style="{ animation: animationStyle }" id="video">
        <source :src="modifiedVideoSource" type="video/mp4">
    </video>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
    props: {
        videoSource: {
            type: String,
            required: true
        },
        continueTo: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const videoRef = ref(null);
        const animationStyle = 'fadeIn 3s forwards';
        const router = useRouter();
        const modifiedVideoSource = ref('');
        const loading = ref(true);

        watch(() => props.videoSource, (newVideoSource) => {
            console.log('videoSource changed:', newVideoSource);
            if (newVideoSource) {
                modifiedVideoSource.value = require('@/assets/video/' + newVideoSource);
                loading.value = false;
                // console.log('modifiedVideoSource:', modifiedVideoSource.value);
            }
        }, { immediate: true });

        const handleVideoEnd = () => {
            document.getElementById('video').style.animation = 'fadeOut 3s forwards'
            setTimeout(() => {
                router.push('/' + props.continueTo)
            }, 3100);
        };

        onMounted(() => {
            goFullScreen();
            setTimeout(() => {
                if (videoRef.value && modifiedVideoSource.value) {
                    videoRef.value.play();
                }
            }, 1000);
        });

        const goFullScreen = () => {
            if (videoRef.value) {
                videoRef.value.requestFullscreen();
            }
        };

        return { videoRef, animationStyle, handleVideoEnd, modifiedVideoSource, loading, goFullScreen };
    }
}
</script>

<style scoped>
    video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    
    video::-webkit-media-controls {
        display: none !important;
    }
        
    video::-moz-media-controls {
        display: none;
    }
    
    video::-ms-media-controls {
        display: none;
    }
</style>