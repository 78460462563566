<template>
  <router-view/>
</template>


<style>
@font-face {
  font-family: 'Rooters';
  src: url('@/assets/fonts/Rooters.woff2') format('woff2'),
        url('@/assets/fonts/Rooters.woff') format('woff'),
        url('@/assets/fonts/Rooters.otf') format('otf');
}

* {
  box-sizing: border-box;
}

#app {
  font-family: Roboto, Arial, sans-serif, swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

html,body {
  margin: 0;
  padding: 0;
  background: black;
}

html {
  font-size: 16px;
}

h1 {
  font-family: Rooters, system-ui, sans-serif, swap;
  text-transform: uppercase;
  font-weight: 300;
}

h2 {
  font-family: Rooters, system-ui, sans-serif, swap;
  text-transform: uppercase;
  font-weight: 300;
}

button, .btn {
  background: url('@/assets/btn-red.png') center/contain no-repeat transparent;
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;
  transition-duration: 100ms;
}

@media screen and (max-width: 1000px) {
    html {
      font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    html {
      font-size: 12px;
    }
}

button:hover, .btn:hover {
  opacity: 0.7;
}
  
.btn-blue {
  background: url('@/assets/btn-blue.png') center/contain no-repeat transparent;
  background-blend-mode: multiply;
}

img {
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

p {
  text-wrap: pretty;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  
}

</style>
