<template>
  <div>
    <button @click="toggleFullScreen">Vollbild ein/aus</button>
  </div>
</template>

<script>
export default {
  methods: {
    toggleFullScreen() {
      const doc = document.documentElement;
      const requestFullScreen =
        doc.requestFullscreen ||
        doc.mozRequestFullScreen ||
        doc.webkitRequestFullScreen ||
        doc.msRequestFullscreen;
      const exitFullScreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // Enter full-screen mode
        requestFullScreen.call(doc);
      } else {
        // Exit full-screen mode
        exitFullScreen.call(document);
      }
    },
  },
};
</script>