<template>
    <div id="gidw_backdrop" :style="{ backgroundImage: `url(${bgImage})`}"></div>
    
    <div class="risskante" id="risskante">
        <div class="inner loggedIn" v-if="loggedIn">
            <img src="../assets/Logo-Skeleton-Stories.png" alt="Skeleton Stories" id="ss-logo">
            <img src="../assets/Logo-GIDW.png" alt="Geister in den Wänden" id="gidw-logo">
            <p>Nach dem Tod ihrer Mutter bricht für Sarah eine Welt zusammen. Ihr Vater versucht, den Verlust seiner Frau zu verdrängen und flüchtet mit seiner Tochter in die Kleinstadt Radenau, wo sie sich nach und nach in die Welt der Geisterbeschwörung verliert, um Kontakt mit ihrer Mutter aufnehmen zu können. Drei Monate später wird Sarah tod in einem Waldstück aufgefunden. Hatte sie doch zu mehr Geistern Kontakt als nur zu ihrer Mutter? Oder steckt am Ende doch eine irdische Ursache dahinter?<br><br>Begebt euch mit Hilfe des Parapsychologen Dr. Corvus auf die Suche nach der Wahrheit über die geheimnisvollen Ereignisse in Radenau.
            </p>
            <button @click.exact="startGame" @click.alt="goToGame">Start Game</button>
        </div>
        <div v-else class="inner">
            <img src="../assets/Logo-Skeleton-Stories.png" alt="Skeleton Stories" id="ss-logo">
            <div>
                <h2>Hinweis:</h2>
                <p>Wir empfehlen das Spielen über den Google Chrome Browser, da dieser i. d. R. performanter ist und das Spiel für Chrome optimiert wurde. In anderen Browsern kann es zu geringen grafischen Abweichungen kommen.<br><br>Die Nutztung der App ist für Desktop-PCs/Laptops optimiert. Auf mobilen Endgeräten kann es zu Abweichungn kommen.</p>
            </div>
            
            <!-- <p>Um das Spiel zu starten, melde dich bitte mit einem Benutzernamen oder einer Email an. Alternativ kannst du auch als Gast ohne Anmeldung spielen. Allerdings kann dein Spielstand dann nicht gespeichert werden!</p> -->
            <button @click="welcome">Verstanden</button>
        </div>
    </div>
  
  
</template>

<script>
import { useAudioStore } from '@/stores/AudioStore'

export default {
    data() {
        return {
            AudioStore: useAudioStore(),
            loggedIn: false,
            bgImage: require('../assets/HandSeeBG.jpg'),
        }
    },
    methods: {
        goToGame() {
            this.AudioStore.bgAudio.pause()
            this.$router.push({name: 'Game'})
        },
        startGame() {
            this.AudioStore.sfxPlay('click')
            this.AudioStore.bgAudioStop()
            this.$router.push({name: 'Video', params: { videoSource: 'FearFactory_Logoanimation.mp4', continueTo: 'medium-test'}})
        },
        welcome() {
            this.AudioStore.sfxPlay('click')
            document.getElementById('risskante').style.animation = 'fadeOut 3s forwards'
            document.getElementById('gidw_backdrop').style.animation = 'fadeOut 2s forwards'
            setTimeout(() => {
                this.$goToFullScreen()
                this.loggedIn = true
                this.AudioStore.bgAudioStart()
                this.bgImage = require('../assets/background.jpg')
                setTimeout(() => {
                    document.getElementById('risskante').style.animation = 'fadeIn 2s forwards'
                    document.getElementById('gidw_backdrop').style.animation = 'fadeIn 3s forwards'                    
                }, 1000)
            }, 3100)
            
        }
    }
}
</script>

<style scoped>
    .risskante {
        position: absolute;
        top: 0;
        left: -10px;
        height: 100%;
        width: 100%;
        transform: translateX(-30%);
        background: black;
        background: url('../assets/Hintergrund-Risskante-links.webp') center/cover no-repeat;
    }
    .inner {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5em;
        width: 50%;
        z-index: 10;
        gap: 2em;
        left: 30vw;
        top: 50%;
        transform: translateY(-50%);
    }
    
    #gidw_backdrop {
        position: fixed;
        top: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background-position-x: 400px;
    }
    #ss-logo, #gidw-logo {
        width: 50%;
    }
</style>