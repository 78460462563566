<template>
  <div v-if="loading">Loading...</div>
  <video v-else ref="videoRef" @ended="$emit('videoEnd', videoSource)" id="video">
    <source :src="modifiedVideoSource" type="video/mp4">
  </video>
</template>

<script>
import { onMounted, ref, watch } from 'vue';

export default {
  props: {
    videoSource: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const videoRef = ref(null);
    const modifiedVideoSource = ref('');
    const loading = ref(true);

    watch(() => props.videoSource, (newVideoSource) => {
      if (newVideoSource) {
        modifiedVideoSource.value = require('@/assets/video/' + newVideoSource);
        loading.value = false;
      }
    }, { immediate: true });

    onMounted(() => {
      setTimeout(() => {
        if (videoRef.value && modifiedVideoSource.value) {
          // videoRef.value.currentTime = videoRef.value.duration - 3;
          videoRef.value.play();
        }
      }, 3100);
    });

    return { videoRef, modifiedVideoSource, loading };
  }
}
</script>

<style scoped>
  video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: black;
    z-index: 1000;
  }
  video::-webkit-media-controls {
  display: none !important;
  }

  video::-moz-media-controls {
  display: none;
  }

  video::-ms-media-controls {
  display: none;
  }
</style>