<template>

    <div class="searchBar">
        <form @submit.prevent="webSearch">
            <input type="text" v-model="webSearchInput" placeholder="Im Web suchen ...">
            <button class="btn-blue">Suchen</button>
        </form>
    </div>
    <!-- Start -->
    <div class="webContent page404" v-if="currentWebPage == 'start'">
        <h2>Internetbrowser</h2>
        <img :src="require('@/assets/Logo-Corvus.png')" alt="Logo Dr. Corvus Parapsychologie">
    </div>
    <!-- Medium Wiekhaus -->
    <div class="webContent" v-else-if="currentWebPage == 'wiekhaus'">
        Medium Wiekhaus
        <button @click="this.$emit('seanceBooked')">Termin zur Séance machen</button>
    </div>
    <!-- Einhorn Apotheke -->
    <div class="webContent" v-else-if="currentWebPage == 'apotheke'">
        Einhorn Apotheke
    </div>
    <!-- Radenauer Regional Express -->
    <div class="webContent" v-else-if="currentWebPage == 'rre'">
        Radenauer Regional Express
    </div>
    <!-- Geisterforum -->
    <div class="webContent" v-else-if="currentWebPage == 'geisterforum'">
        <div class="gf-body">
            <div class="gf-header">
                <div class="gf-topbar">
                    <img :src="require('@/assets/internet/geisterforum/LogoGF.webp')" alt="Geister Forum online">
                    <input type="text" placeholder="Forum durchsuchen ..." id="gf-input">
                </div>
                <img :src="require('@/assets/internet/geisterforum/geisterforum_header.jpg')" alt="Header Img Geisterforum">
            </div>
            <div class="gf-content">
                <div class="gf-sidebar">
                    <b>Nächste Termine</b>
                    <span>Geistersuche mit Thorsten<br>27. Juni, 22 Uhr</span>
                    <br>
                    <b>Neueste Beiträge</b>
                    <span><u>Der Fernseher</u><br>von geist_2093</span><br>
                    <span><u>Der Spieleabend</u><br>von lennard1us</span><br>
                    <span><u>Der Albtraum</u><br>von my_wife</span>
                </div>
                <div class="gf-main" v-if="gf_chat.length">
                    <p>Chat mit <i>SpritSeeker</i></p>
                    <div class="gf-chatBubble" v-for="(chatItem, index) in gf_chat" :key="index" >
                        <div class="gf-chatBubble-sidebar">
                            <div class="gf-chatBubble-user" :class="{ 'spukdetektiv': chatItem[0] == 'Spukdetektiv' }"></div>
                        </div>
                        <div class="gf-chatBubble-main">
                            <div class="gf-chatBubble-meta">
                                <p>von {{ chatItem[0] }} • {{ chatItem[1] }} • {{ chatItem[2] }} Uhr</p>
                            </div>
                            <div class="gf-chatBubble-text">
                                <p>{{ chatItem[3] }}</p>
                                <video src="../assets/video/paranormal_video.mp4" controls controlsList="nodownload nofullscreen noremoteplayback" v-if="index == 4" @click="this.$emit('revealDoc', 'Geisterforum')">Video konnte nicht geladen werden.</video>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Wolfgang Hohlbein Gymnasium -->
    <div class="webContent" v-else-if="currentWebPage == 'whg'">
        <img :src="require('@/assets/WHG-Aktuelles-Sommerfest-V2.jpg')" alt="WHG Website">
    </div>
    <!-- 404 -->
    <div class="webContent page404" v-else>
        <h2>Fehler 404</h2>
        <p>Die gesuchte Seite konnte leider nicht gefunden werden.</p>
        <img :src="require('@/assets/Logo-Corvus.png')" alt="Logo Dr. Corvus Parapsychologie">
    </div>

</template>

<script>
export default {
    data() {
        return {
            webSearchInput: 'geisterforum.online',
            currentWebPage: 'start',
            webRouting: {
                'start': [''],
                'wiekhaus': ['medium-wiekhaus.de', 'www.medium-wiekhaus.de', 'https://medium-wiekhaus.de', 'https://www.medium-wiekhaus.de'],
                'apotheke': ['einhorn-apotheke.de', 'www.einhorn-apotheke.de', 'https://einhorn-apotheke.de', 'https://www.einhorn-apotheke.de'],
                'rre': ['rre.de', 'www.rre.de', 'https://rre.de', 'https://www.rre.de'],
                'geisterforum': ['geisterforum.online', 'www.geisterforum.online', 'https://geisterforum.online', 'https://www.geisterforum.online'],
                'whg': ['wh-gymnasium.de', 'www.wh-gymnasium.de', 'https://wh-gymnasium.de', 'https://www.wh-gymnasium.de'],
            },
            gf_chat: [
                ['SpiritSeeker', '11.07.2022', '19:23', 'Und du? Bist du auch hier, um mit Geistern zu kommunizieren? \n\nIch habe eine spezielle Frage. Ich versuche, mit meiner verstorbenen Mutter zu kommunizieren, aber ich habe Angst, den falschen Geist anzurufen. Was soll ich tun?'],
                ['Spukdetektiv', '11.07.2022', '19:36', 'Hallo SpiritSeeker, es ist verständlich, dass du besorgt bist. Es ist wichtig, vorsichtig zu sein und sicherzustellen, dass du eine klare und positive Verbindung herstellst. Hast du bereits Erfahrung damit, mit Geistern zu kommunizieren?'],
                ['SpiritSeeker', '11.07.2022', '19:42', 'Ein Wenig mit meiner Nachbarin. Sie hilft mir, aber es dauert mir zu lange und so langsam hab ich das Gefühl, mehr als nur den Geist meiner Mutter zu rufen. Ich möchte so gerne mit meiner Mutter sprechen, aber ich möchte keine negativen Energien anziehen.'],
                ['Spukdetektiv', '11.07.2022', '19:58', 'Verstehe. Es ist eine sensible Situation, und es ist wichtig, dass du dich sicher fühlst. Ich empfehle dir, dich vor der Kommunikation mit deiner Mutter innerlich zu zentrieren. Atme tief ein und konzentriere dich auf positive Gedanken und Erinnerungen an sie. Visualisiere eine liebevolle und schützende Energie um dich herum. Kannst du mir Beispiele zeigen? Hast du eventuell Videos oder Fotos von negativen Ereignissen?'],
                ['SpiritSeeker', '11.07.2022', '20:31', 'Was hälst du davon?'],
                ['SpiritSeeker', '11.07.2022', '20:32', 'Gestern habe ich in meinem Zimmer merkwürdige Geräusche gehört, Schatten gesehen und eine beängstigende Kälte gespürt. Es fühlt sich einfach nicht richtig an. Nicht als wäre meine Mutter hier. Ich habe das Gefühl, dass der Geist von Sabrina hier ist. Das Mädchen dass vor mir hier gelebt hat. Die Tochter meiner Nachbarin. Ihr Tod wurde zwar nie bestätigt, allerdings hab ich da so ein Gefühl…'],
                ['Spukdetektiv', '11.07.2022', '20:35', 'Wichtig ist, dass du und dein Vater regelmäßig kontrollieren, ob euer Haus sicher ist und sich dort niemand anderes aufhält. Bei dem Fenster in deinem Zimmer kann ja jeder in den ersten Stock klettern.'],
                ['SpiritSeeker', '11.07.2022', '20:39', 'Moment, ich hab doch meinen Vater gar nicht erwähnt. Oder dass ich in einem Haus wohne. Oder wo!!'],
                ['Spukdetektiv', '11.07.2022', '20:39', 'Nicht? Aber naja, ich dachte wenn deine Mutter nicht mehr lebt und auf Grund des Videos.'],
                ['SpiritSeeker', '11.07.2022', '20:40', 'Warte mal. Auf deinem Profilfoto…. Das ist doch der Wagen von meinem Nachbar. Jakob, du ekelhafter Stalker!! Das geht gar nicht. Man spioniert nicht seine Nachbarn aus!!'],
                ['Spukdetektiv', '11.07.2022', '20:41', 'Sarah!! Warte, natürlich wollte ich dir keine Angst machen!'],
                ['SpiritSeeker', '11.07.2022', '20:42', 'Du Freak! Das wird nicht schön für dich, wenn das raus kommt, dass du junge Mädchen im Internet stalkst. Wenn ich das Papa erzähle, wanderst du in den Knast!'],
                ],
        }
    },
    methods: {
        webSearch() {
            this.currentWebPage = Object.keys(this.webRouting).find(key => this.webRouting[key].includes(this.webSearchInput)) || '404';
        }
    }
}
</script>

<style scoped>
    input[type="text"] {
      background: url('../assets/line-horizontal.png') center bottom/contain no-repeat transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      padding: .5em 0;
      margin-right: 1em;
      min-width: 30%;
    }

    input:focus, select:focus {
      outline: none;
    }

    .searchBar {
       height: 4em;
        background-color: gray;
    }

    .searchBar.light {
        background: white;
        color: black;
    }
    .searchBar.light input {
        color: black;
    }

    .webContent {
        overflow-y: scroll;
        height: 75dvh;
    }

    .webContent img {
        width: 100%;
        height: auto;
    }

    .page404 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;
    }
    .page404 img {
        width: 10%;
    }

    .gf-body {
        display: flex;
        flex-direction: column;
        background-color: #000;
    }

    .gf-topbar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 3em;
        height: 4em;
        background-color: #151517;
        padding: 1em 2em;
    }
    
    .gf-header img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        overflow: hidden;
    }
    .gf-content {
        display: grid;
        grid-template-columns: 1fr 3fr 1px;
        gap: 2em;
        position: relative;
        padding: 2em 0;
    }
    .gf-topbar img {
        height: 2em;
        object-fit: contain;
        width: min-content;
    }

    .gf-content::before {
        content: "";
        position: absolute;
        top: 2em;
        left: 25%;
        height: calc(100% - 4em);
        width: 1px;
        background-color: rgb(92, 92, 92);
    }

    .gf-sidebar {
        background: #1A1A1B;
        border-radius: .7em;
        height: fit-content;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 1em;
        gap: .1em;
        color: #CFCFCF;
    }

    .gf-sidebar b {
        font-size: 1.1em;
    }
    .gf-main {
        display: flex;
        flex-direction: column;
        gap: 2em;
        text-align: left;
    }

    .gf-chatBubble {
        background: #1A1A1B;
        border: #343536 1px solid;
        border-radius: .7em;
        display: grid;
        grid-template-columns: 4em 1fr;
    }

    .gf-chatBubble-sidebar {
        background: #151517;
        border-radius: 1em 0 0 1em;
    }

    .gf-chatBubble-user {
        width: 2.5em;
        height: 2.5em;
        border-radius: 50%;
        margin: 1em auto;
        background-image: url('../assets/internet/geisterforum/spiritseeker.jpg');
        background-position: center;
        background-size: cover;
    }
    .spukdetektiv {
        background-image: url('../assets/internet/geisterforum/spukdetektiv.jpg');
    }

    .gf-chatBubble-main {
        padding: 1em;
    }
    .gf-chatBubble-meta {
        font-size: 0.8em;
        color: #464646;
    }
    #gf-input {
        background-color: #1A1A1B;
        border: #343536 1px solid;
        color: #CFCFCF;
        border-radius: 3em;
        width: 15em;
        background-image: none;
        padding-left: 1em;
    }
    .gf-chatBubble-text video{
        width: 60%;
    }
    .gf-chatBubble-text {
        color: #CFCFCF;
        text-wrap: pretty;
        text-align: left;
    }
</style>