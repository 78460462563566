<template>
    <div v-if="eventStage == '0'">
        <div v-if="showButtons" class="buttons">
          <button class="btn-blue single-btn" @click="handleButtonClick(1)">Haus betreten</button>
        </div>
        <video @timeupdate="handleTimeUpdate" @canplaythrough="handleCanPlayThrough" ref="videoRef">
            <source :src="require('@/assets/video/event/open_house.webm')" type="video/webm">
            <source :src="require('@/assets/video/event/open_house.mp4')" type="video/mp4">
        </video>
    </div>
    <div v-if="eventStage == '1'">
        <div v-if="showButtons" class="buttons">
          <button class="btn-blue single-btn" @click="handleButtonClick(2)">Treppe hochgehen</button>
        </div>
        <video @timeupdate="handleTimeUpdate" @canplaythrough="handleCanPlayThrough" ref="videoRef">
            <source :src="require('@/assets/video/event/ground_floor.webm')" type="video/webm">
            <source :src="require('@/assets/video/event/ground_floor.mp4')" type="video/mp4">
        </video>
    </div>
    <div v-if="eventStage == '2'">
        <div v-if="showButtons" class="buttons">
          <button class="btn-blue hub-btn-1" @click="handleButtonClick(3)">Türe Links</button>
          <button class="btn-blue hub-btn-2" @click="handleButtonClick(4)">Türe Mitte Links</button>
          <button class="btn-blue hub-btn-3" @click="handleButtonClick(5)">Türe Mitte Rechts</button>
          <button class="btn-blue hub-btn-4" @click="handleButtonClick(6)">Türe Rechts</button>
        </div>
        <video @timeupdate="handleTimeUpdate" @canplaythrough="handleCanPlayThrough" ref="videoRef">
            <source :src="require('@/assets/video/event/hub.webm')" type="video/webm">
            <source :src="require('@/assets/video/event/hub.mp4')" type="video/mp4">
        </video>
    </div>
    <div v-if="eventStage == '3'">
        <div v-if="showButtons" class="buttons">
          <button class="btn-blue single-btn" @click="handleButtonClick(2)">zurück</button>
        </div>
        <video @timeupdate="handleTimeUpdate" @canplaythrough="handleCanPlayThrough" ref="videoRef">
            <source :src="require('@/assets/video/event/living_room.webm')" type="video/webm">
            <source :src="require('@/assets/video/event/living_room.mp4')" type="video/mp4">
        </video>
    </div>
    <div v-if="eventStage == '4'">
        <video @ended="handleVideoEnd" @canplaythrough="handleCanPlayThrough" ref="videoRef">
            <source :src="require('@/assets/video/event/sarah.webm')" type="video/webm">
            <source :src="require('@/assets/video/event/sarah.mp4')" type="video/mp4">
        </video>
    </div>
    <div v-if="eventStage == '5'">
        <div v-if="showButtons" class="buttons">
          <button class="btn-blue single-btn" @click="handleButtonClick(2)">zurück</button>
        </div>
        <video @timeupdate="handleTimeUpdate" @canplaythrough="handleCanPlayThrough" ref="videoRef">
            <source :src="require('@/assets/video/event/bathroom.webm')" type="video/webm">
            <source :src="require('@/assets/video/event/bathroom.mp4')" type="video/mp4">
        </video>
    </div>
    <div v-if="eventStage == '6'">
        <div v-if="showButtons" class="buttons">
          <button class="btn-blue single-btn" @click="handleButtonClick(2)">zurück</button>
        </div>
        <video @timeupdate="handleTimeUpdate" @canplaythrough="handleCanPlayThrough" ref="videoRef">
            <source :src="require('@/assets/video/event/ruempelkammer.webm')" type="video/webm">
            <source :src="require('@/assets/video/event/ruempelkammer.mp4')" type="video/mp4">
        </video>
    </div>
</template>


<script>
import { useAudioStore } from '@/stores/AudioStore'
export default {
  data() {
    return {
      videoRef: null,
      modifiedVideoSource: '',
      loading: true,
      videoSource: 'open_house.webm',
      showButtons: false,
      eventStage: '0',
      AudioStore: useAudioStore(),
    };
  },
  watch: {
    videoSource(newVideoSource) {
      if (newVideoSource) {
        this.modifiedVideoSource = require('@/assets/video/event/' + newVideoSource);
        this.loading = false;
      }
    },
  },
  emits: ['eventEnd'],
  methods: {
    handleVideoEnd() {
      this.$emit('eventEnd');
    },
    handleCanPlayThrough() {
      this.$refs.videoRef.play();
      
    },
    handleButtonClick(nextStage) {
      this.AudioStore.sfxPlay('click')
      this.showButtons = false
      this.eventStage = nextStage
    },
    handleTimeUpdate() {
      if (this.$refs.videoRef.duration - this.$refs.videoRef.currentTime <= 5) {
        this.showButtons = true
      }
    },
  },
  mounted() {
    this.videoSource = 'open_house.webm';
  },
};
</script>

<style scoped>
  video, .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: black;
    z-index: 1000;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: white;
  }

  video::-webkit-media-controls {
  display: none !important;
  }

  video::-moz-media-controls {
  display: none;
  }

  video::-ms-media-controls {
  display: none;
  }

  .buttons {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    animation: fadeIn 3s forwards;
  }

  .buttons button {
    position: absolute;
  }

  .single-btn {
    bottom: 15%;
    transform: translateX(-50%);
  }

  .hub-btn-1 {
    bottom: 40%;
    left: 5%;
  }
  .hub-btn-2 {
    top: 15%;
    left: 20%;
  }
  .hub-btn-3 {
    top: 15%;
    right: 20%;
  }
  .hub-btn-4 {
    bottom: 40%;
    right: 5%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>