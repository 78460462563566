<template>
    <div class="backdrop" v-if="showModal" @click.self="toggleModal">
        <div class="modal" ref="modal" :class="{ internet: modalType === 'internet' }">
            <div class="closeIcon" @click.self="toggleModal"></div>
            <!-- MODAL type=phone START -->
            <div v-if="this.modalType === 'phone'">
                <Phone ref="phoneComponent" @audioStatus="audioPlaying = $event" @docs="$emit('revealDoc', $event)" />
            </div>
            <!-- MODAL type=phone END -->
            <!-- MODAL type=documents START -->
            <div v-if="this.modalType === 'documents'" class="sammlung">
                <h2>Sammlung</h2>
                <div class="row gap-5">
                    <div class="docs_imgIcon" v-if="this.$parent.documentsStatus['Intro']" @click="toggleShowVideo('intro')"><img :src="imgIconSrc" alt="Foto/Video Icon"><br>Intro</div>
                    <div class="docs_audioIcon" v-if="this.$parent.documentsStatus['Notruf']" ref="docs_notruf" @click="togglePlaying('docs_notruf')"><img :src="audioIconSrc" alt="Audio Icon"><br>Notruf Sarah</div>
                    <div class="docs_imgIcon" v-if="this.$parent.documentsStatus['Tatortfoto']" @click="toggleShowImage('tatort')"><img :src="imgIconSrc" alt="Foto/Video Icon"><br>Tatortfoto</div>
                    <div class="docs_audioIcon" v-if="this.$parent.documentsStatus['Mailbox']" ref="docs_mailbox" @click="togglePlaying('docs_mailbox')"><img :src="audioIconSrc" alt="Audio Icon"><br>Mailbox</div>
                </div>
                <div class="row gap-5">
                    <div class="docs_imgIcon" v-if="this.$parent.documentsStatus['Geisterforum']" @click="toggleShowVideo('geisterforum')"><img :src="imgIconSrc" alt="Foto/Video Icon"><br>Geisterforum</div>
                    <div class="docs_audioIcon" v-if="this.$parent.documentsStatus['Seance']" ref="docs_seance" @click="togglePlaying('docs_seance')"><img :src="audioIconSrc" alt="Audio Icon"><br>Séance</div>
                    <div class="docs_imgIcon" v-if="this.$parent.documentsStatus['Grundrisse']" @click="toggleShowImage('grundrisse')"><img :src="imgIconSrc" alt="Foto/Video Icon"><br>Grundriss</div>
                    <div class="docs_imgIcon" v-if="this.$parent.documentsStatus['Videoanruf']" @click="toggleShowVideo('videoanruf')"><img :src="imgIconSrc" alt="Foto/Video Icon"><br>Videoanruf Corvus</div>
                </div>
                <div v-show="showDocsImg" id="docsImageContainer" @click="toggleShowImage('close')"><img :src="docsCurrentImage"><div class="closeIcon"></div></div>
                <div v-show="showDocsVideo" id="docsVideoContainer" @click="toggleShowVideo('close')"><video :src="docsCurrentVideo" ref="docsVideo" type="video/mp4" @ended="toggleShowVideo('close')">Video konnte nicht geladen werden.</video><div class="closeIcon"></div></div>
            </div>
            <!-- MODAL type=documents END -->
            <!-- MODAL type=internet START -->
            <div v-if="this.modalType === 'internet'">
                <Internet @revealDoc="$emit('revealDoc', $event)" @seanceBooked="$emit('seanceBooked')"/>
            </div>
            <!-- MODAL type=internet END -->
            <!-- MODAL type=settings START -->
            <div v-if="this.modalType === 'settings'">
                <h2 @click.alt="this.$parent.skip()">Einstellungen</h2>
                <label for="volume">Hintergrundmusik</label>
                <input type="range" name="volume" v-model="AudioStore.bgAudio_volume" min="1" max="100" value="50"><br>
                <label for="volume">Soundeffekte</label>
                <input type="range" name="volume" v-model="AudioStore.sfx_volume" min="1" max="100" value="50"><br>
                <p class="error" v-if="AudioStore.sfx_volume < 30">Warnung! Über die Soundeffekte werdet ihr auf wichitge Elemente im Spiel hingewiesen. Achtet darauf, dass mindestens so laut eingestellt sind, dass ihr auf diese Aufmerksam werdet.</p>
                <br><br>
                <FullScreenButton />
                <br><br>
                <div class="serviceLinks">
                    <a href="https://fearfactory.games/skeleton-stories-app/terms-conditions/" target="_blank">AGB</a>
                    <span> | </span>
                    <a href="https://fearfactory.games/skeleton-stories-app/privacy-policy/" target="_blank">Datenschutz</a>
                    <span> | </span>
                    <a href="https://fearfactory.games/rechtliches/impressum/" target="_blank">Impressum</a>
                </div>
                
            </div>
            <!-- MODAL type=settings END -->
            <!-- MODAL type=solve START -->
            <div v-if="this.modalType === 'solve'">
                <h2 @click="showHints = !showHints">Fall lösen</h2>
                <form @submit.prevent="tryToSolve">
                    <div class="row">
                        <p class="subhead">Wer ist für den Tod von <b>Sarah Presch</b> verantwortlich?</p><span @click="showHintsSarah = !showHintsSarah">(?)</span>
                        <select v-model="solveQSarah" :disabled="caseSolved" @change="solveError = null">
                            <option v-for="(suspect, index) in suspects" :key="index" :value="index">{{ suspect }}</option>
                        </select>
                        <div v-show="showHintsSarah" class="row hints">
                            <span @click="this.$parent.$refs.chat.addMessage(this.solveHints['sarah']['hint_1'], null, true)" class="hint">Hinweis 1</span><span> | </span>
                            <span @click="this.$parent.$refs.chat.addMessage(this.solveHints['sarah']['hint_2'], null, true)" class="hint">Hinweis 2</span><span> | </span>
                            <span @click="this.$parent.$refs.chat.addMessage(this.solveHints['sarah']['hint_3'], null, true)" class="hint">Hinweis 3</span>
                        </div>
                    </div>
                    <div class="row">
                        <p class="subhead">Wer ist für den Tod von <b>Sabrina Wiekhaus</b> verantwortlich?</p><span @click="showHintsSabrina = !showHintsSabrina">(?)</span>
                        <select v-model="solveQSabrina" :disabled="caseSolved" @change="solveError = null">
                            <option v-for="(suspect, index) in suspects" :key="index" :value="index">{{ suspect }}</option>
                        </select>
                        <div v-show="showHintsSabrina" class="row hints">
                            <span @click="this.$parent.$refs.chat.addMessage(this.solveHints['sabrina']['hint_1'], null, true)" class="hint">Hinweis 1</span><span> | </span>
                            <span @click="this.$parent.$refs.chat.addMessage(this.solveHints['sabrina']['hint_2'], null, true)" class="hint">Hinweis 2</span><span> | </span>
                            <span @click="this.$parent.$refs.chat.addMessage(this.solveHints['sabrina']['hint_3'], null, true)" class="hint">Hinweis 3</span>
                        </div>
                    </div>
                    <span class="error" v-show="solveError">{{ solveError }}</span><br><br>
                    <button :disabled="caseSolved">Prüfen</button>
                    
                </form>
            </div>
            <!-- MODAL type=solve END -->
            <!-- MODAL type=para START -->
            <div v-if="this.modalType === 'para'" class="paraModal">
                <h2 class="head">{{ paraQuestions.questions[paraIndex].question }}</h2><br>
                <div v-if="!paraResponse">
                    <p class="subhead">Handelt es sich um ein paranomales Phänomen?</p>
                    <form @submit.prevent="handleSubmit">
                        <div class="radioButtons">
                            <input type="radio" id="yes" name="para" value="yes" v-model="paraRadio" @input="AudioStore.sfxPlay('typing'); this.paraError = null">
                            <label for="yes" style="margin-right: 4em;">Ja</label>
                            <input type="radio" id="no" name="para" value="no" v-model="paraRadio" @input="AudioStore.sfxPlay('typing'); this.paraError = null">
                            <label for="no">Nein</label>
                        </div><br>
                        <label for="docNumber">Dokumentennummer:</label>
                        <input type="text" placeholder="000a" v-model="paraAnswer" maxlength="4" id="docNumber" @input="AudioStore.sfxPlay('typing'); this.paraError = null">
                        <br>
                        <button>Prüfen</button><br><br>
                        <span class="error" v-show="paraError">{{ paraError }}</span>
                    </form>
                </div>
                <div v-else>
                    <p>{{ paraResponse }}</p>
                    <button v-if="this.$parent.paraStatus[this.paraIndex]" @click="toggleModal">zurück</button>
                    <button v-else @click="paraResponse = null; AudioStore.sfxPlay('click')">zurück</button>
                </div>
            </div>
            <!-- MODAL type=para END -->
        </div>
    </div>
</template>

<script>
import Phone from '@/components/Phone.vue'
import Video from '@/views/Video.vue'
import Internet from '@/components/Internet.vue'
import FullScreenButton from '@/components/FullScreenButton.vue'
import para_questions from '@/data/para_questions.json'
import para_responses from '@/data/para_responses.json'
import gidw_data from '@/data/gidw.json'
import { useAudioStore } from '@/stores/AudioStore'
import { watch } from 'vue';


export default {
    data() {
        return {
            AudioStore: useAudioStore(),
            showModal: false,
            modalType: '',
            suspects: gidw_data.suspects,
            solveQSarah: 0,
            solveQSabrina: 0,
            caseSolved: false,
            solveError: null,
            showHintsSarah: false,
            showHintsSabrina: false,
            solveHints: gidw_data.solveHints,
            // Paranormal Questions
            paraIndex: null,
            paraQuestions: para_questions,
            paraAnswer: null,
            paraRadio: null,
            paraResponses: para_responses.responses,
            paraResponse: null,
            documentNumbers: gidw_data.documentNumbers,
            paraError: null,
            // Phone
            audioPlaying: false,
            // Documents (Sammlung)
            imgIconSrc: require('@/assets/icons/Sammlung-Foto.png'),
            audioIconSrc: require('@/assets/icons/Sammlung-Tonspur.png'),
            docsAudio: {
                'docs_notruf': new Audio(require('@/assets/audio/Notruf-2.0.mp3')),
                'docs_mailbox':  new Audio(require('@/assets/audio/phone/Mailbox.mp3')),
                'docs_seance':  new Audio(require('@/assets/audio/phone/SEANCE_Telefonat.mp3')),
            },
            docsImgSrcs: {
                'tatort': require('@/assets/Beweismittel.jpg'),
                'grundrisse': require('@/assets/847_Grundriss-mit-Notizen.png'),
            },
            docsVideoSrcs: {
                'intro': require('@/assets/video/intro.mp4'),
                'geisterforum': require('@/assets/video/paranormal_video.mp4'),
                'videoanruf': require('@/assets/video/event/sarah.mp4'),
            },
            docsAudioPlaying: false,
            docsCurrentAudio: null,
            audioRefs: [],
            showDocsImg: false,
            showDocsVideo: false,
            docsCurrentImage: '',
            docsCurrentVideo: '',
        }
    },
    methods: {
        openModal(type, index = null) {
            this.modalType = type
            this.paraIndex = index
            if (type === "para" && this.$parent.paraStatus[this.paraIndex]) {
                let paraAnswer = this.paraQuestions.questions[this.paraIndex].paranormal[0]
                let evidenceAnswer = this.paraQuestions.questions[this.paraIndex].evidence[0]
                this.paraResponse = this.paraResponses[this.paraIndex][paraAnswer][evidenceAnswer]
            }

            // this.$refs.modal.classList.add(type)
            this.toggleModal()
        },
        toggleModal() {
            if (this.audioPlaying) {
                this.$refs.phoneComponent.stopAudio();
            }
            if (this.docsAudioPlaying) {
                this.docsAudio[this.docsCurrentAudio].pause()
                this.docsAudio[this.docsCurrentAudio].currentTime = 0
                this.docsAudioPlaying = false
                this.docsCurrentAudio = null
            }
            if (this.modalType === 'settings' && this.showModal) {
                this.$parent.$refs.timer.timer_start()
            }            
            if (this.showModal) {
                    this.AudioStore.sfxPlay('modalClose')
                    if (this.AudioStore.bgAudio.paused) {this.AudioStore.bgAudioStart()}
                } else {
                    this.AudioStore.sfxPlay('modalOpen')
                }
            setTimeout(() => {
                this.showModal = !this.showModal
                this.clearParaForm()
                if (this.paraResponse && !this.$parent.paraStatus[this.paraIndex]) { this.paraResponse = null }
                }, 100) //Timeout to adjust audio timing & prevent double click
        },
        handleSubmit() {
            // check if all fields are filled
            if (!this.paraRadio || !this.paraAnswer) {
                this.paraError = "Bitte füllen Sie alle Felder aus."
                this.AudioStore.sfxPlay('wrong')
                return
            } else {
                this.paraError = null
            }

            //check if answer is correct
            for (let i = 0; i < this.paraQuestions.questions[this.paraIndex].paranormal.length; i++) {
                if (this.paraRadio === this.paraQuestions.questions[this.paraIndex].paranormal[i] && this.paraAnswer === this.paraQuestions.questions[this.paraIndex].evidence[i]) {
                    this.$emit('setStatusTrue', this.paraIndex)
                    break;
                }
                if (i === this.paraQuestions.questions[this.paraIndex].paranormal.length - 1) {
                    this.AudioStore.sfxPlay('wrong')
                }
            }

            // give response text
            setTimeout(() => {
                if (this.paraAnswer in this.paraResponses[this.paraIndex][this.paraRadio]) { // if answer is in paraResponses
                    this.paraResponse = this.paraResponses[this.paraIndex][this.paraRadio][this.paraAnswer]
                    // console.log(this.paraRadio)
                } else if (this.paraAnswer in this.paraResponses[5]) { // answer is an incomplete document number
                    this.paraResponse = this.paraResponses[5][this.paraAnswer]
                } else if (!this.documentNumbers.includes(this.paraAnswer)) { //answer is not a possible document number
                    this.paraResponse = this.paraResponses[6]
                    // console.log(this.paraAnswer + " is not a possible document number")
                } else {
                    this.paraResponse = this.paraResponses[this.paraIndex]['default']
                }
            }, 100)
            
        },
        clearParaForm() {
            this.paraRadio = null
            this.paraAnswer = null
        },
        togglePlaying(reference) {
            if (this.docsAudioPlaying && reference !== this.docsCurrentAudio) {
                this.docsAudio[this.docsCurrentAudio].pause()
                this.docsAudio[this.docsCurrentAudio].currentTime = 0
                this.$refs[this.docsCurrentAudio].classList.remove('active');
                this.docsAudioPlaying = false
                this.docsCurrentAudio = null
            }
            this.$refs[reference].classList.toggle('active')
            this.docsAudioPlaying = !this.docsAudioPlaying
            if (this.docsAudio[reference].paused) {
                this.docsAudio[reference].play()
                this.docsCurrentAudio = reference
            } else {
                this.docsAudio[reference].pause()
                this.docsAudio[reference].currentTime = 0
                this.docsCurrentAudio = null
            }
        },
        toggleShowImage(reference) {
            if (reference != 'close') {
                // this.$refs['docs_img'].src = this.docsImgSrcs[reference]
                this.docsCurrentImage = this.docsImgSrcs[reference]
            }
            this.AudioStore.sfxPlay('click')
            this.showDocsImg = !this.showDocsImg
        },
        toggleShowVideo(reference) {
            if (reference != 'close') {
                this.docsCurrentVideo = this.docsVideoSrcs[reference]
                // console.log("Source changed to: " + this.docsCurrentVideo)
            }
            this.AudioStore.sfxPlay('click')
            this.showDocsVideo = !this.showDocsVideo
            if (!this.$refs.docsVideo.paused && reference != 'close') {
                // console.log("Video paused")
                this.AudioStore.bgAudioStart()
                this.$refs.docsVideo.pause()
                this.$refs.docsVideo.currentTime = 0
            } else if (reference === 'close') {
                // console.log("Video closed")
                this.docsCurrentVideo = null
            } else {
                // console.log("Video played")
                setTimeout(() => {
                    this.$refs.docsVideo.play().catch(err => {
                        console.log(err)
                    })
                    this.AudioStore.bgAudioStop()
                }, 500)
            }
        },
        tryToSolve() {
            if (this.solveQSarah === 1 && this.solveQSabrina === 5) {
                this.caseSolved = true
                this.AudioStore.bgAudioStop()
                this.$parent.$refs.timer.timer_stop()
                this.AudioStore.sfxPlay('gameStart')
                setTimeout(() => {
                    document.getElementById('gidw_backdrop').style.animation = 'fadeOut 3s forwards'
                }, 1000)
                setTimeout(() => {
                    this.$router.push({name: 'Video', params: { videoSource: 'outro.mp4', continueTo: 'Start'}})
                }, 4100)
            } else {
                this.AudioStore.sfxPlay('wrong')
                this.solveError = "Inkorrekt. Versuchen Sie es erneut."
            }
        }
    },
    mounted() {
        this.audioRefs = Object.keys(this.docsAudio),
        this.audioRefs.forEach(key => {
            this.docsAudio[key].onended = () => {
                if (this.$refs[key].classList.contains('active')) {
                    this.$refs[key].classList.remove('active');
                }
                this.docsAudioPlaying = !this.docsAudioPlaying;
            }
        })
    },
    components: {
        Phone,
        Video,
        FullScreenButton,
        Internet
    },
    setup() {
    const AudioStore = useAudioStore();
    
    watch(() => AudioStore.bgAudio_volume, (newVolume) => {
      AudioStore.bgAudio.volume = newVolume / 100;
    });
  }
}
</script>

<style scoped>
    .backdrop, #docsImageContainer, #docsVideoContainer {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        backdrop-filter: blur(3px);
        transition-duration: 100ms;
        z-index: 110;
    }
    #docsVideoContainer {
        background: rgba(30, 30, 30, 0.5);
    }
    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
        background-image: url('../assets/popUp-black.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 80%;
        height: 80%;
        padding: 3em;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .internet {
        width: 97%;
        height: 97%;
    }

    .modal .closeIcon {
        position: absolute;
        top: 30px;
        right: 30px;
        height: 30px;
        width: 30px;
        background: url('../assets/x.png') center/contain no-repeat;
        z-index: 101;
    }

    h2 {
        text-wrap: pretty; /* No good browser support, but if so, text is pretty <3 */
    }

    input[type="radio"] {
    display: none;
    }

    input[type="radio"] + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    }

    input[type="radio"] + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px dashed #ffffff;
    display: inline-block;
    }

    input[type="radio"]:checked + label:after {
    content: "✓";
    position: absolute;
    left: 0;
    top: -20px;
    font-size: 40px;
    display: inline-block;
    }

    input[type="text"] {
      background: url('../assets/line-horizontal.png') center bottom/contain no-repeat transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      padding: .5em 0;
      margin-left: 1em;
      width: 50px;
      text-align: center;
    }

    input:focus, select:focus {
      outline: none;
    }

    .sammlung {
        display: flex;
        flex-direction: column;
        gap: 2em;
        align-items: center;
        position: relative;
    }
    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 1em;
    }

    .gap-5 {
        gap: 5em;
    }
    .docs_imgIcon:hover, .docs_audioIcon:hover {
        opacity: .8;
    }
    .docs_imgIcon img, .docs_audioIcon img {
        width: 5em;
        height: auto;
        src: url('../assets/icons/Sammlung-Foto.png');
    }

    .sammlung .active img {
        opacity: .5;
        animation: pulseOpacity 1s infinite;
    }
    .sammlung .active {
        position: relative;
    }
    .sammlung .active::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 25px;
    background-color: transparent;
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
    border-width: 0 5px;
    border-style: solid;
    z-index: 105;
    }
    @keyframes pulseOpacity {
        0% {
            opacity: .6;
        }
        50% {
            opacity: .3;
        }
        100% {
            opacity: .6;
        }
    }

    #docsImageContainer img, #docsVideoContainer video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 80%;
        max-height: 80%;
    }

    select {
        width: 180px;
        height: 2em;
        padding: .5em;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: white;
        border: none;
        font-size: 1em;
        background: url('../assets/line-horizontal.png') center bottom/contain no-repeat transparent;
        text-align: center;
    }

    .error {
      color: #C41721;
      font-size: 0.8rem;
      text-transform: uppercase;
      margin: 2em 0 3em 0;
    }

    input[type="range"] {
        margin-left: 3em;
        transform: translateY(4px);
    }
    input[type=range]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        background: #fff; /* Change this to your desired color */
        height: 3px;
    }
    input[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: none;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background: #ffffff; /* Change this to your desired color */
        margin-top: -7px;
    }

    .hint {
        color: rgb(32, 88, 109);

    }
    .hint:hover, .serviceLinks a:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    .hints {
        gap: 0.5em;
    }

    .serviceLinks a {
        text-decoration: none;
        color: white;
    }

</style>