
<template>
    <div class="fullscreen-popup">
        <div class="caller" v-if="caller === 'marianne'">
            <h1>Marianne Wiekhaus</h1>
            <img src="../assets/caller-wiekhaus.webp" alt="Marianne Wiekhaus">
        </div>
        <div class="caller" v-if="caller === 'corvus'">
            <h1>Dr. Corvus</h1>
            <img src="../assets/caller-corvus.webp" alt="Dr. Corvus">
        </div>
        <div class="row" v-if="caller === 'both'">
            <div class="caller">
                <h1>Marianne Wiekhaus</h1>
                <img src="../assets/caller-wiekhaus.webp" alt="Marianne Wiekhaus">
            </div>
            <div class="caller">
                <h1>Dr. Corvus</h1>
                <img src="../assets/caller-corvus.webp" alt="Dr. Corvus">
            </div>
        </div>
        <div class="callBtn" @click="handleButtonClick" v-if="!inCall"></div>
    </div>
</template>

<script>
import { useAudioStore } from '@/stores/AudioStore'

export default {
    props: {
        callerID: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            AudioStore: useAudioStore(),
            caller: this.$props.callerID,
            inCall: false,
        }
    },
    methods: {
        handleButtonClick() {
            this.AudioStore.sfxStop()
            this.inCall = true
            if (this.caller === 'marianne') {
                this.AudioStore.phoneCall('SEANCE_Telefonat.mp3')
                this.AudioStore.phone.addEventListener('ended', () => {
                    if (this.caller == 'both') {
                        this.AudioStore.sfxPlay('phoneToot')
                        this.caller = 'corvus'
                        this.AudioStore.phoneCall('SEANCE_Ende.mp3')
                    } else if (this.caller == 'corvus') {
                        this.AudioStore.sfxPlay('phoneEnd')
                        this.$parent.incomingCall = false
                        this.$parent.handleSeanceEnd()
                        this.AudioStore.phone.removeEventListener('ended', () => {})
                    }
                })
                setTimeout(() => {
                    this.AudioStore.sfxPlay('phoneToot')
                    this.caller = 'both'
                }, 3000);
            } else if (this.caller === 'corvus') {
                this.$parent.incomingCall = false
                this.$parent.showEventVideo = true
            }
            
        }
    },
    mounted() {
        this.AudioStore.bgAudioStop()
        this.AudioStore.sfxPlay('phoneRing', true)
    }
}
</script>

<style scoped>
    .fullscreen-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: black;
        /* backdrop-filter: blur(10px); */
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4em;
        flex-direction: column;
    }

    .callBtn {
        background: rgb(23, 137, 47);
        background: url('../assets/icons/Telefon.png') center/contain no-repeat transparent;
        border-radius: 50%;
        border: none;
        width: 5em;
        height: 5em;
    }
    .callBtn:hover {
        opacity: 0.7;
    }

    .caller {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }

    .caller img {
        width: 15em;
        height: 15em;
    }

    .row {
        display: flex;
        flex-direction: row;
        gap: 5em;
    }
</style>