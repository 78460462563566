<template>
    <div class="chatContainer">
        <div class="chat" ref="chatMessages">
            <div v-for="message in messages" :key="message.id" :class="message.type">
                <div v-if="message.isHint">
                    <span class="first-line">{{ message.text.split('\n')[0] }}</span>
                    <span v-if="message.text.split('\n').length > 1"><br>{{ message.text.split('\n').slice(1).join('\n') }}</span>
                </div>
                <div v-else>
                    {{ message.text }}
                </div>
                
                
                <span v-if="message.numberFound">
                    <span v-for="number in message.numberFound" :key="number">
                        <br>
                        <br>
                        <span>Dokument {{ number }}</span><br>
                        <span @click="sendHint(number, 1)" class="hint">Hinweis 1</span><span> | </span>
                        <span @click="sendHint(number, 2)" class="hint">Hinweis 2</span><span> | </span>
                        <span @click="sendHint(number, 3)" class="hint">Hinweis 3</span>
                    </span>
                </span>
            </div>
        </div>
        <div class="input">
            <input type="text" v-model="newMessage" @keyup.enter="sendMessage" name="userChatInput" @input="AudioStore.sfxPlay('typing')" placeholder="Nachricht ..."><button class="btn-blue" @click="sendMessage">Senden</button>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';
import hints_data from '@/data/hints.json';
import { useAudioStore } from '@/stores/AudioStore'

export default {
    data () {
        return {
            AudioStore: useAudioStore(),
            messages: reactive([]),
            newMessage: ref(''),
            id: 0,
            keywords: {
                'hallo': 'Hallo, wenn Sie fragen zu einem oder mehreren Dokumenten haben, dann schicken Sie mir einfach die Dokumentennummer.',
                'fearfactory': 'Ah, die FearFactory! Das sind ziemlich gute Kollegen von mir, die mir helfen meine Fälle optisch aufzubereiten und zu organisieren.',
            },
            postSeanceKeywords: ['birke', 'astloch', 'baum'],
            foundKeywords: [],
            foundNumbers: [],
            numbersFound: false,
            timeout: 0,
            hints: hints_data,
            hintLabels: ['hint_1', 'hint_2', 'hint_3'],
        }
    },
    methods: {
        sendMessage() {
            const text = this.newMessage.trim();
            if (text) {
                this.AudioStore.sfxPlay('click');
                this.messages.push({ id: this.id++, text, type: 'user' });
                this.newMessage = '';
                this.analyzeMessage(text);
            }
            this.scrollToBottom();
        },
        analyzeMessage(text) {
            const toLowerCaseText = text.toLowerCase();

            // Additional keywords for after Seance
            if (this.$parent.lookForHouseKey) {
                this.postSeanceFoundKeywords = this.postSeanceKeywords.filter(keyword => toLowerCaseText.includes(keyword.toLowerCase()));
                if (this.postSeanceFoundKeywords.length > 0) {
                    this.addMessage('Sehr gut Kollegen! Ich hab ihn. Ich gehe nun rein und melde mich sofort wieder bei Ihnen.')
                    this.$parent.callerImg = 'corvus';
                    this.$parent.keyFound = true;
                    this.$parent.lookForHouseKey = false;
                    setTimeout(() => {
                        this.$parent.incomingCall = true;
                    }, 33000);
                }
            } else {
                // Check if text contains any keywords
                this.foundKeywords = Object.keys(this.keywords).filter(keyword => toLowerCaseText.includes(keyword.toLowerCase()));
                if (this.foundKeywords.length > 0) {
                    this.foundKeywords.forEach(foundKey => {
                        this.addMessage(this.keywords[foundKey]);
                    });
                }

                // Check if text contains any document numbers
                let foundNumbers = this.$parent.$refs.modal.documentNumbers.filter(number => toLowerCaseText.includes(number.toLowerCase()));
                if (foundNumbers.length > 0) {
                    let message = "Wie kann ich dir bei diesem Dokument helfen?\n";
                    if (foundNumbers.length > 1) {
                        message = "Wie kann ich dir bei diesen Dokumenten helfen?\n";
                    }
                    // Check for double numbers (with & without letter)
                    const numberWithLetterPattern = /^\d{3}[a-zA-Z]$/;
                    let numbersWithoutLetters = new Set();

                    foundNumbers.forEach(element => {
                        if (numberWithLetterPattern.test(element)) {
                            const numberWithoutLetter = element.slice(0, 3);
                            numbersWithoutLetters.add(numberWithoutLetter);
                        }
                    });

                    foundNumbers = foundNumbers.filter(element => !numbersWithoutLetters.has(element));
                    this.addMessage(message, foundNumbers);
                }
            }
            

        },
        addMessage(message, numbers = null, instant = false, hint = false) {
            if (instant) {
                this.timeout = 0;
            } else {
                this.timeout = Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000; // Random number between 1000 and 3000 so that reply is not instant and feels human
            }
            setTimeout(() => {
                this.messages.push({ id: this.id++, text: message, type: 'corvus' });
                if (numbers) {
                    this.messages[this.messages.length - 1].numberFound = numbers;
                }
                if (hint) {
                    this.messages[this.messages.length - 1].isHint = true;
                }
                this.AudioStore.sfxPlay('message');
                this.scrollToBottom();
            }, this.timeout);
        },
        scrollToBottom() {
            this.$nextTick(() => {
                this.$refs['chatMessages'].scrollTop = this.$refs['chatMessages'].scrollHeight;
            });
        },
        sendHint(number, hint) {
            this.AudioStore.sfxPlay('click');
            this.addMessage(`Hinweis ${hint} für Dokument ${number} \"${this.hints[number].name}\": \n\n${this.hints[number][this.hintLabels[hint - 1]]}`, null, true, true);
        }
    }
}
</script>

<style scoped>
    .chatContainer {
        display: flex;
        flex-direction: column;
        width: stretch;
        width: -webkit-fill-available;
        height: 100%;
        justify-content: flex-end;
    }

    .chat {
        scroll-behavior: smooth;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 1em;
        

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    .user, .corvus {
        background-blend-mode: multiply;
        padding: 1em;
        width: 80%;
        text-align: left;
        overflow-x: clip;
        word-wrap: break-word;
        white-space: pre-wrap;
        text-wrap: pretty;
        line-height: 1.2em;
    }

    .user {
        background: rgba(32, 88, 109, .5);
        align-self: flex-end;
    }

    .corvus {
        background: rgba(16, 16, 36, 0.7);
    }

    .input {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 1em;
    }

    input {
        background: url('../assets/line-horizontal.png') center bottom/contain no-repeat transparent;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: .5em 0;
        margin-right: 1em;
        width: stretch;
        width: -webkit-fill-available;
        width: -moz-available;
    }

    input:focus {
        outline: none;
    }

    .hint {
        /* color: rgb(241, 231, 173); */
        color: rgb(32, 88, 109);
    }
    .hint:hover {
        cursor: pointer;
        opacity: 0.7;
    }
    .first-line {
        color: rgb(180, 180, 180);
    }
</style>