<template>
    <div class="phone">
        <div class="phoneNumber">
            <input type='text' disabled :value="phoneNumber" id="displayNumber">
        </div>
        <div class="buttons">
            <div class="row" v-for="(row, index) in numbers" :key="index">
                <button class="numberBtn" v-for="(number, i) in row" :key="number" @click="AudioStore.sfxPlay('phoneToot'); addNumber(number.toString())" :disabled="calling">
                    {{ number }}
                    <div>{{ texts[index][i] }}</div>
                </button>
            </div>
            <div class="row">
                <button class="numberBtn darker" @click="AudioStore.sfxPlay('phoneToot'); addNumber('*')" :disabled="calling">*</button>
                <button class="numberBtn" @click="AudioStore.sfxPlay('phoneToot'); addNumber('0')" :disabled="calling">0<div>+</div></button>
                <button class="numberBtn darker" @click="AudioStore.sfxPlay('click'); clearNumbers()" :disabled="calling">#<div>clear</div></button>
            </div>
            <div class="row">
                <button class="numberBtn callBtn" @click="call()" v-if="!calling"></button>
                <button class="numberBtn putDown" @click="stopAudio()" v-else></button>
            </div>
        </div>
    </div>
</template>

<script>
import { useAudioStore } from '@/stores/AudioStore'

export default {
    data() {
        return {
            AudioStore: useAudioStore(),
            phoneNumber: '',
            numbers: [
                [1, 2, 3],
                [4, 5, 6],
                [7, 8, 9],
            ],
            texts: [
                [' ', 'ABC', 'DEF'],
                ['GHI', 'JKL', 'MNO'],
                ['PQRS', 'TUV', 'WXYZ'],
            ],
            calling: false,
            phoneCall: new Audio(require('@/assets/audio/phone/Mailbox.mp3')),
        }
    },
    methods: {
        addNumber(number) {
            if (this.phoneNumber.length < 15) {
               this.phoneNumber += number 
            }
        },
        clearNumbers() {
            this.phoneNumber = ''
        },
        call() {
            if (this.phoneNumber.length > 0) {
                if (this.phoneNumber === '01876309500') {
                    this.calling = true
                    this.phoneCall.src = require('@/assets/audio/phone/Mailbox.mp3')
                    this.phoneCall.play()
                    this.$emit('audioStatus', true);
                    this.$emit('docs', 'Mailbox')
                } else {
                    this.phoneCall.src = require('@/assets/audio/phone/KeinAnschluss.mp3')
                    this.phoneCall.play()
                    this.calling = true
                    this.$emit('audioStatus', true);
                }
            }
        },
        stopAudio() {
            if (this.phoneCall) {
                this.phoneCall.pause()
                this.calling = false
                this.$emit('audioStatus', false);
            }
        }
    },
    mounted() {
        this.phoneCall.onended = () => {
            this.calling = false;
            this.$emit('audioStatus', false);
            }
    }
}
</script>

<style scoped>
     input {
      background: url('../assets/line-horizontal.png') center bottom/contain no-repeat transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      padding: .5em 0;
      margin-right: 1em;
    }
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1em;
    }

    .numberBtn {
        background: rgb(108, 108, 108);
        border-radius: 50%;
        border: none;
        color: #fff;
        width: 3em;
        height: 3em;
        font-size: 16px;
        padding: .5em 0;
        margin-right: 1em;
    }
    .numberBtn div {
        font-size: 10px;
        font-weight: 300;
    }
    .row {
        display: flex;
        flex-direction: row;
    }
    .numberBtn.darker {
        background: rgb(80, 80, 80);
    }

    .phoneNumber {
        margin: 2em;
    }

    .phoneNumber input {
        text-align: right;
    }
    .callBtn {
        background: url('../assets/icons/Telefon.png') center/contain no-repeat transparent;
    }
    .putDown {
        background: url('../assets/icons/Telefon-auflegen.png') center/contain no-repeat transparent;
    }

    button:disabled {
        opacity: 0.5;
    }
</style>