import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

const pinia = createPinia()
const app = createApp(App)
app.use(pinia)

app.config.globalProperties.$goToFullScreen = function () {
    const doc = document.documentElement;
    const requestFullScreen =
        doc.requestFullscreen ||
        doc.mozRequestFullScreen ||
        doc.webkitRequestFullScreen ||
        doc.msRequestFullscreen;
    const exitFullScreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement){
        // Enter full-screen mode
        try {
            requestFullScreen.call(doc);
          } catch (error) {
            console.error('Failed to request fullscreen:', error);
          }
    }
};

app.use(router).mount('#app')