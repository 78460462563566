import { createRouter, createWebHistory } from 'vue-router'
import Game from '../views/Game.vue'
import CorvusTest from '../views/CorvusTest.vue'
import Video from '../views/Video.vue'
import Start from '../views/Start.vue'

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start,
    meta: { title: 'App - Skeleton Stories' }
  },
  {
    path: '/game/:intro?',
    name: 'Game',
    component: Game,
    meta: { title: 'Geister in den Wänden - Skeleton Stories' },
    props: true
  },
  {
    path: '/medium-test',
    name: 'Medium-Test',
    component: CorvusTest,
    meta: { title: 'App - Skeleton Stories' }
  },
  {
    path: '/video/:videoSource/:continueTo',
    name: 'Video',
    component: Video,
    meta: { title: 'Geister in den Wänden - Skeleton Stories' },
    props: true
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  document.title = to.meta.title || 'Geister in den Wänden - Skeleton Stories';
});

export default router
