<template>
  
    <div id="gidw_backdrop">
      <video loop muted @loadeddata="videoLoaded = true" autoplay>
        <source src="../assets/video/Corvus-BG-Video-Website.webm" type="video/webm">
      </video>
      <div class="inner">
        <h1>Haben Sie die Gabe mit dem Jenseits in kontakt zu treten?</h1>
        <p>Erweisen Sie sich würdig, um Dr. Corvus bei seinen paranormalen Fällen zu unterstützen?</p>
        <a class="btn" href="https://medium.skeleton-stories.de/" target="_blank" @click="AudioStore.bgAudioStop(); AudioStore.sfxPlay('gameStart')">Test starten</a><br>
        <div class="input">
          <!-- <img src="../assets/icons/Icon-Login-Passwort.png" alt="" class="icon"> -->
          <input type="text" placeholder="Passwort" v-model.trim="userInput" maxlength="15" name="corvusPassword" @keydown.enter="handleSubmit" :disabled="success" @input="this.AudioStore.sfxPlay('typing')">
          <button @click="handleSubmit" class="btn" :disabled="success">Enter</button><br>
          <span class="error" v-if="pwdError">{{ pwdError }}</span>
        </div>
        
      </div>
        
    </div>
  
</template>

<script>
import { useAudioStore } from '@/stores/AudioStore'

export default {
    data() {
      return {
        AudioStore: useAudioStore(),
        pwdError: '',
        passcode: 'demonic',
        userInput: '',
        videoLoaded : false,
        success: false,
      }
    },
    methods: {
      handleSubmit() {
        if (this.userInput.toLowerCase() === this.passcode) {
          if (this.AudioStore.bgAudio) {this.AudioStore.bgAudioStop()}
          this.success = true
          this.pwdError = ""
          this.AudioStore.sfxPlay('success')
          
          setTimeout(() => {
            this.AudioStore.sfxPlay('gameStart')
            document.getElementById('gidw_backdrop').style.animation = 'fadeOut 3s forwards'
          }, 1000)
          setTimeout(() => {
            this.$router.push('/Game/gameStart')
          }, 4100)
        } else if (this.userInput === ''){
          this.AudioStore.sfxPlay('wrong')
          this.pwdError = "Bitte gib ein Passwort ein"
        } else {
          this.AudioStore.sfxPlay('wrong')
          this.pwdError = "Passwort falsch"
        }
      },
    },
  mounted() {
    this.$goToFullScreen()
    // Start BG Audio on load
    this.AudioStore.bgAudioStart()
  }
  }
</script>

<style scoped>
    #gidw_backdrop {
      position: fixed;
      top: 0;
      background: url('../assets/bg-mediumTest.jpg') center/cover no-repeat;
      width: 100%;
      height: 100%;
    }

    #gidw_backdrop video,
    .inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    input {
      background: url('../assets/line-horizontal.png') center bottom/contain no-repeat transparent;
      border: none;
      color: #fff;
      font-size: 16px;
      padding: .5em 0;
      margin-right: 1em;
    }

    input:focus {
      outline: none;
    }

    .input {
      margin-top: 5em;
      position: relative;
    }

    p {
      margin: 3em 0;
    }

    .error {
      color: #C41721;
      font-size: 14px;
      text-transform: uppercase;
    }

    </style>
