<template>
    <div id="gidw_backdrop">
        <IncomingCall :callerID="callerImg" v-if="incomingCall"/>
        <VideoEvent v-if="showEventVideo" @eventEnd="handleEventEnd" />
        <FullScreenVideo :videoSource="fullScreenVideoSource" v-if="showFullScreenVideo" @videoEnd="handleFullScreenVideoEnd" ref="fullScreenVideo" id="fullScreenVideo"/>
        <Modal ref="modal" id="modal" @setStatusTrue="setStatusTrue" @revealDoc="revealDocument" @seanceBooked="handleSeanceBooked"/>
        <div class="inner col flex">
            <div id="topBar" class="space-between flex row">
                <div class="flex"><Timer ref="timer" class="timer" @updateElapsedTime="handleElapsedTime"/></div>
                
                <div><img class="corvus_logo" src="../assets/Logo-Corvus.png" @click.alt="this.incomingCall = true"></div>
                <div><div class="btn_settings" @click="openSettings">Einstellungen</div></div>
            </div>
            <div class="flex row space-between">
                <div class="flex col" id="sidenav">
                    <button class="btn-blue" @click="openModal('phone')">Telefon</button>
                    <button class="btn-blue relative" @click="openModal('documents')"><div class="notifyBatch" v-show="unreadDocuments"></div>Sammlung</button>
                    <button class="btn-blue" @click="openModal('internet')">Internet</button>
                </div>
                <!-- <div class="flex col">
                    <h2>Dr. Corvus</h2>
                </div> -->
            </div>
            <div class="row flex space-between" id="bottomBar">
                <div id="parafragen">
                    <img v-for="(status, index) in this.paraStatus" 
                        :key="index"
                        :src="status ? require(`../assets/Paranormal-Check-${index + 1}-grün.webp`) : require(`../assets/Paranormal-Check-${index + 1}-rot.webp`)" 
                        alt="" 
                        :id="`btn-para-${index + 1}`" 
                        :class="{ solved: status }"
                        @click="openModal('para', index)">
                    
                    <span v-if="!solvedStatus" class="label">Handelt es <br>sich um einen <br>paranormalen Fall?</span>
                    <button v-else class="btn-blue" @click="openModal('solve')">Fall lösen</button>
                </div>
                <div id="chat" class="flex">
                    <Chat ref="chat"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Timer from '@/components/Timer.vue'
import Modal from '@/components/Modal.vue'
import Chat from '@/components/Chat.vue'
import FullScreenButton from '@/components/FullScreenButton.vue'
import FullScreenVideo from '@/components/FullScreenVideo.vue'
import VideoEvent from '@/components/VideoEvent.vue'
import IncomingCall from '@/components/IncomingCall.vue'
import { useAudioStore } from '@/stores/AudioStore'

export default {
    data() {
      return {
        AudioStore: useAudioStore(),
        paraStatus: [false, false, false, false, false],
        solvedStatus: false,
        documentsStatus: {
            'Intro': true,
            'Notruf': false,
            'Tatortfoto': false,
            'Geisterforum': false,
            'Mailbox': false,
            'Seance': false,
            'Grundrisse': false,
            'Videoanruf': false,
        },
        automatedMessages: { // Time passes in milliseconds (10000 er Schritte) / Message for Chat as string / Documents to activate as array / 2nd dependency to show message
            '60000': ['Hallo Kollegen, \nich befinde mich bereits auf dem Weg zum Fundort. Wenn ich Neuigkeiten für Sie habe, melde ich mich auf diesem Wege.\nBis gleich. C.',[], true],
            '70000': ['In der Sammlung finden Sie übrigens alle medialen Inhalte, die Sie im Verlauf des Falles erhalten.',[], true],
            '120000': ['Ach, Kollegen! Ich habe vergessen zu erwähnen, dass ich eine wichtige Datei bereits erhalten habe. Unser Opfer hat kurz vor ihrem Ableben einen interessanten Notruf abgesendet. Am besten schauen Sie sich das mal näher an.',['Notruf'], true],
            '600000': ['Mhhh, diese Teebeutel lagen in dem Waldstück zwischen dem Fundort der Leiche und der Straße.\n\nDas Foto habe ich Ihnen in der Sammlung hinterlegt.',['Tatortfoto'], true],
            '1500000': ['Diese Marianne Wiekhaus scheint mit eine interessante Persönlichkeit zu sein. Vielleicht machen Sie mal einen Termin zur Séance bei ihr.',[], 'seance'],
            '2400000': ['Geehrte Forscher, Sie sollten wirklich einen Termin zur Séance bei Frau Wiekhaus machen. Ich werde das Gefühl nicht los, dass wir so sicher mehr über sie erfahren.',[], 'seance'],
            '3000000': ['Ich habe soeben für Sie einen Termin für eine Séance bei Marianne Wiekhaus für Sie gemacht. Vielleicht finden wir irgendwas heraus. Ich schalte mich bei dem Telefonat dazu. Bitte kümmern Sie sich um die Vorbereitungen. Bis gleich.',[], 'seance'],
            '6000000': ['Vergessen Sie nicht die paranormalen Fragen des Falles zu beantworten. Sobald Sie alle fünf beantwortet haben, können Sie versuchen den Fall zu lösen.',[], 'paraFragen'],
        },
        unreadDocuments: false,
        showFullScreenVideo: false,
        fullScreenVideoSource: '',
        seanceBooked: false,
        houseKeyFound: false,
        incomingCall: false,
        callerImg: 'marianne',
        showEventVideo: false,
        postSeanceMessages: ['Wenn Sie wissen, wo sich der Ersatzschlüssel befindet, geben Sie mir bitte sofort bescheid.', 'Kollegen, konnten Sie schon herausfinden, wo ich einen Schlüssel finde? Beeilen Sie sich!','Irgendwo muss es hier doch einen Zweitschlüssel geben. Hat Sarah vielleicht irgendwo mal einen passenden Ort für diesen erwähnt?','Wir müssen uns beeilen, Forscher. Sie müssen zügig herausfinden, wo ich nach dem Schlüssel suchen muss.','Ich meine mich zu erinnern, in Sarahs Briefverkehr etwas in die Richtung gelesen zu haben. Schauen Sie doch bitte mal, ob Sie dort einen Hinweis finden.','Der Schlüssel liegt bestimmt unmittelbar vor der Eingangstüre. Die Zeit drängt! Haben Sie etwas gefunden, Kollegen?','Ich hab ihn! Er war in einem Astloch der Birke versteckt. Ich gehe nun rein und melde mich sofort wieder bei Ihnen.'],
        keyFound: false,
        lookForHouseKey: false,
      }
    },
    methods: {
        openSettings() {
            this.$refs.timer.timer_stop()
            this.$refs.modal.openModal('settings')
        },
        openModal(type, index = null) {
            this.$refs.modal.openModal(type, index)
            setTimeout(() => {
                this.unreadDocuments = false
            }, 101)
            
        },
        setStatusTrue(index) {
            this.paraStatus[index] = true
            this.solvedStatus = this.paraStatus.every(status => status === true)
            this.AudioStore.sfxPlay('success')
            if (this.solvedStatus) {
                this.$refs.chat.addMessage('Sehr gut Kollegen, die Lösung des Rätsels ist zum Greifen nah! Drücken Sie auf den "Fall lösen" Button, um den Fall abzuschließen. Falls Sie weitere Hilfe benötigen, klicken Sie dort auf das entsprechende (?) Symbol.')
            }
        },
        handleElapsedTime(time) {
            // if (time == 360000 && this.seanceBooked == true) {
            //     this.incomingCall = true
            //     return
            // }
            // console.log("New Elapsed Time: " + time)
            if (time in this.automatedMessages) {
                if (this.automatedMessages[time][2] == 'seance' && this.seanceBooked == true) {return}
                if (this.automatedMessages[time][2] == 'paraFragen' && this.solvedStatus == true) {return}
                this.$refs.chat.addMessage(this.automatedMessages[time][0])
                if (this.automatedMessages[time][1].length !== 0) {
                    this.automatedMessages[time][1].forEach(doc => {
                        this.revealDocument(doc)
                    })
                }
            }
        },
        revealDocument(doc) {
            this.documentsStatus[doc] = true
            if (!this.$refs.modal.showModal) { // If modal is not open, show notification
                this.unreadDocuments = true 
            }
        },
        skip() {
            for (let key in this.documentsStatus) {
                this.documentsStatus[key] = true;
            }
            // this.$refs.timer.elapsedTime = 60 * 60000 // 60 minutes
            this.$refs.timer.skipTime = 60 * 60000 // 60 minutes
            this.$refs.chat.addMessage('Erledigt.', null, true)
        },
        handleFullScreenVideoEnd(videoSource) {
            if (videoSource === 'intro.mp4') {
                this.$nextTick(() => {
                   document.getElementById('fullScreenVideo').style.animation = 'fadeOut 3s forwards'
                })
                
                setTimeout(() => {
                    this.showFullScreenVideo = false
                    this.AudioStore.bgAudioStart()
                    this.$refs.timer.timer_start()
                }, 3100);
            }
        },
        handleFullScreenVideoStart(source) {
            this.fullScreenVideoSource = source
            this.showFullScreenVideo = true
            this.AudioStore.bgAudioStop()
            this.$refs.timer.timer_stop()
        },
        handleSeanceEnd() {
            this.AudioStore.bgAudioStart()
            this.lookForHouseKey = true
            let i = 0
            let interval = setInterval(() => {
                if (this.keyFound) {
                    this.lookForHouseKey = false
                    clearInterval(interval)
                    return
                }
                this.$refs.chat.addMessage(this.postSeanceMessages[i])
                i++
                if (i >= this.postSeanceMessages.length) {
                    clearInterval(interval)
                    this.callerImg = 'corvus'
                    this.keyFound = true
                    setTimeout(() => {
                        this.incomingCall = true
                    }, 33000);
                    return
                }
            }, 60000);
        },
        handleEventEnd() {
            this.AudioStore.sfxPlay('phoneEnd')
            this.showEventVideo = false
            this.AudioStore.bgAudioStart()
            this.$refs.timer.timer_start()
            this.revealDocument('Seance')
            this.revealDocument('Videoanruf')
            this.$refs.chat.addMessage('Kollegen, das war ja äußerst merkwürdig. Ich habe das Gefühl, dass wir hier noch nicht am Ende sind. Ich werde mich nochmal bei Ihnen melden, wenn ich mehr weiß.')
            setTimeout(() => {
                this.$refs.chat.addMessage('Also, das war ja schlimm. Ich bin direkt rüber in die Wohnung von Frau Wiekhaus gegangen und habe mich umgesehen. Auf dem Weg ist mir auch Herr Jakob Unterbrink entgegengekommen, der ja, wie Sie sicher wissen, direkt gegenüber wohnt. Er hat mir ein paar Unterlagen von früher mitgegeben, die bei seinen Ermittlungen damals eine Rolle gespielt haben, aber bis heute keinen Sinn ergaben.')
                setTimeout(() => {
                    this.$refs.chat.addMessage('Ich lasse Ihnen die Unterlagen direkt zukommen. Bitte, öffnen Sie nun den Umschlag von mir und machen Sie sich mit den neuen Information vertraut.')
                }, 8000)
                
                setTimeout(() => {
                    this.$refs.chat.addMessage('Ich habe Ihnen auch noch eine Datei mit Notizen von mir in der Sammlung hinterlegt. Sie sollte Ihnen dabei helfen einen Überblick darüber zu erlangen, was genau gerade passiert ist.')
                    this.revealDocument('Grundrisse')
                }, 20000)
            }, 10000);
        },
        handleSeanceBooked() {
            this.seanceBooked = true
            setTimeout(() => {
                this.$refs.chat.addMessage('Interessant, Sie haben einen Termin mit Marianne Wiekhaus zur Séance gemacht. Ich werde mich gleich dazuschalten. Vielleicht können wir so mehr in Erfahrung bringen.')
            }, 10000);
            setTimeout(() => {
                this.incomingCall = true
            }, 20000);
        }
    },
    mounted() {
        // this.$goToFullScreen()

        document.getElementById('gidw_backdrop').style.animation = 'fadeIn 3s forwards'

        if(this.$route.params.intro === 'gameStart') {
            this.fullScreenVideoSource = 'intro.mp4'
            this.showFullScreenVideo = true
        } else {
            // Start BG Audio on load
            this.AudioStore.bgAudioStart()
            // Start Timer on load
            this.$refs.timer.timer_start()
        }
        
    },
    components: {
        Timer,
        Modal,
        Chat,
        FullScreenButton,
        FullScreenVideo,
        IncomingCall,
        VideoEvent
    }
}
</script>

<style scoped>
    #gidw_backdrop {
      position: fixed;
      top: 0;
      background: url('../assets/background.jpg') center/cover no-repeat;
      width: 100%;
      height: 100%;
    }

    #gidw_backdrop .inner {
      padding: 50px;
      /* border: solid 1px red; */
      height: 100%; 
    }

    #topBar {
      /* border: solid 1px blue; */
      height: 15%;
      margin-bottom: 2em;
    }

    #topBar :nth-child(1){
        min-width: 20%;
        min-width: 150px;
    }

    #topBar :nth-child(2) img{
        max-width: 20%;
    }
    #topBar :nth-child(3){
        min-width: 20%;
    }

    #bottomBar {
      height: fill-available;
      align-items: flex-end;
    }

    #chat {
        position: fixed;
        right: 50px;
        bottom: 50px;
        width: 40%;
        height: 60%;
        justify-content: flex-end;
        /* border: red solid 1px; */
        align-items: flex-end;
    }
    #sidenav {
        gap: 1em;
    }
    .btn_settings {
      text-transform: uppercase;
      height: min-content;
      float: right;
      transition-duration: 100ms;
    }
    .btn_settings:hover {
      opacity: 0.7;
    }
    .flex {
        display: flex;
    }
    .row {
        flex-direction: row;
    }
    .col {
        flex-direction: column;
    }
    .space-between {
        justify-content: space-between;
    }

    #parafragen {
        position: relative;
        flex-grow: 2;
    }

    #parafragen img {
        max-height: 10rem;
        position: absolute;
        transition-duration: 100ms;
    }
    #parafragen img:not(.solved):hover {
        transform: translateY(-10px);
        opacity: 0.8;
    }

    #parafragen .label, #parafragen button {
        position: absolute;
        top: -6.25rem;
        left: 8.75rem;
        font-family: 'Rooters', 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 1.5em;
    }
    #parafragen button {
        left: 8.75rem;
        animation: pulse 1300ms infinite;
    }

    #parafragen img:nth-child(1) {
        top: -12.5rem;
        left: 1.25rem;
        rotate: -30deg;
    }
    #parafragen img:nth-child(2) {
        top: -17.5rem;
        left: 6.25rem;
        rotate: -20deg;
    }
    #parafragen img:nth-child(3) {
        top: -19rem;
        left: 13.125rem;
        rotate: 0deg;
    }
    #parafragen img:nth-child(4) {
        top: -17.5rem;
        left: 20rem;
        rotate: 20deg;
    }
    #parafragen img:nth-child(5) {
        top: -12.5rem;
        left: 25rem;
        rotate: 30deg;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    #modal {
        z-index: 100;
    }
    .relative {
        position: relative;
    }
    .notifyBatch {
        width: .7em;
        height: .7em;
        background: #C41721;
        border-radius: 50%;
        position: absolute;
        right: .7em;
        top: .7em;
    }
</style>