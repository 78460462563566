<template>
    <div class="timer">
        <div class="time">{{ formatTime }}</div>
        <div>Timer</div>
    <!-- <button @click="timer_start">Start</button>
    <button @click="timer_clear">Clear</button>
    <button @click="timer_stop">Stop</button> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            timer: null,
            startTime: null,
            elapsedTime: 0, // in milliseconds
            isPaused: false,
            counter: 0, // -> elapsed seconds
            skipTime: null,
        };
    },
    computed: {
        formatTime() {
            const seconds = Math.floor(this.elapsedTime / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);

            return `${this.pad(hours)}:${this.pad(minutes % 60)}:${this.pad(seconds % 60)}`;
        },
    },
    methods: {
        timer_start() {
            if (this.timer) return; // Timer is already running

            this.startTime = Date.now() - this.elapsedTime;
            this.timer = setInterval(() => {
                if (!this.isPaused) {
                    this.elapsedTime = Date.now() - this.startTime;
                    // if (Math.abs(this.elapsedTime % 10000 - 0) < 100) {
                    //     this.$emit('updateElapsedTime', this.formatTime)
                    // console.log('TIME EMITTED')
                    // console.log(this.formatTime)
                    //}
                    this.counter++
                    if (this.counter % 10 === 0) {
                        this.$emit('updateElapsedTime', Math.round(this.elapsedTime / 1000) * 1000)
                    }
                    if (this.skipTime !== null) {
                        this.startTime -= this.skipTime
                        console.log('Skipped ' + this.skipTime + 'ms')
                        this.skipTime = null
                    }
                    
                }
            }, 1000);
        },
        timer_clear() {
            if (this.timer) {
                this.timer_stop();
            }
            this.elapsedTime = 0;
        },
        timer_stop() {
            clearInterval(this.timer);
            // console.log('Timer stopped. Final time taken: ' + this.formatTime)
            this.timer = null;
            this.startTime = null;
            this.isPaused = false;
        },
        pad(value) {
            return String(value).padStart(2, '0');
        },
    },
};
</script>

<style scoped>
.timer .time {
    font-family: "Rooters";
    font-size: 2em;
}
.timer {
    text-transform: uppercase;
}
</style>