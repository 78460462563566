import { defineStore } from 'pinia'

export const useAudioStore = defineStore('audio', {
    state: () => ({ 
        sfx: {
            'success': 'richtig.mp3',
            'wrong': 'falsch.wav',
            'modalOpen': 'modal_open.mp3',
            'modalClose': 'modal_close.wav',
            'typing': 'Tippen.mp3',
            'click': 'click.wav',
            'message': 'message_ping.mp3',
            'gameStart': 'game_start.mp3',
            'phoneRing': 'phone_ringtone.mp3',
            'phoneToot': 'phone_toot.mp3',
            'phoneEnd': 'phonecall_end.wav',
        },
        bgAudio: new Audio(require('@/assets/audio/main-loop.mp3')),
        bgAudio_volume: 50,
        sfx_volume: 50,
        phone: new Audio(require('@/assets/audio/phone/SEANCE_Telefonat.mp3')),
        currentPlayPromise: null,
    }),

    // getters: {
      
    // },

    actions: {
      bgAudioStart() {
        this.bgAudio.volume = this.bgAudio_volume/100
        this.bgAudio.loop = true
        this.bgAudio.play().catch(error => {
          console.error('Playback failed because the user didn\'t interact with the document first:', error)
        })
      },
      bgAudioStop() {
        this.bgAudio.pause()
      },
      sfxPlay(effect, loop = false) {
        // Create a new Audio object for each play() request
        this.sfxAudio = new Audio(require('@/assets/sfx/'+ this.sfx[effect]));
        this.sfxAudio.volume = this.sfx_volume/100;
        if (loop) {
          this.sfxAudio.loop = true;
        }

        // Start playing the audio
        this.sfxAudio.play().catch(error => {
          console.error(error);
        });
      },
      sfxStop() {
        this.sfxAudio.pause()
        this.sfxAudio.loop = false
      },
      phoneCall(audio) {
        this.phone.src = require('@/assets/audio/phone/'+ audio)
        this.phone.play()
      },
      phoneCallStop() {
        this.phone.pause()
        this.phone.currentTime = 0
      },
    }
  })